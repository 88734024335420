import React from 'react';
import styled from '@emotion/styled';

const FullWidthImageHeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin-top: -120px;

  @media only screen and (max-width: 45.9375em) {
    margin-top: -20px;
  }
  @media only screen and (max-width: 28.125em) {
    margin-top: 0;
  }

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const FullWidthImageHeaderInner = styled.div`
  padding: 200px 30px;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: 'DM Serif Text';
    font-weight: 400;
    font-size: 65px;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 20px;

    @media only screen and (max-width: 48em) {
      font-size: 32px;
    }
  }
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;

    @media only screen and (max-width: 48em) {
      font-size: 18px;
      line-height: 1.5;
    }
  }
`;

const FullWidthImageHeaderContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
`;

const FullWidthImageHeaderInnerLink = styled.div`
  margin-top: 20px;

  a {
    font-size: 22px;
    line-height: 180%;
    font-weight: 700;

    @media only screen and (max-width: 48em) {
      font-size: 18px;
      line-height: 1.5;
    }
  }
`;

const HeaderInsights = ({
  header_text,
  subtitle_text,
  color_theme,
  background_color,
  header_text_color,
  subtitle_text_color,
  left_image,
  left_video,
  cta_text,
  cta_link,
  nonLazyMode,
  cta_link_target,
  customFontSize,
  props,
}) => {
  return (
    <FullWidthImageHeaderContainer>
      {left_video && left_video.url && left_video.url.includes('.mp4') ? (
        <video autoPlay loop playsInline muted>
          <source src={left_video.url} type="video/mp4" />
        </video>
      ) : (
        <img src={left_image.url} alt="Image" />
      )}
      <FullWidthImageHeaderContainerOverlay />
      <FullWidthImageHeaderInner>
        {header_text.text ? <h1>{header_text.text}</h1> : null}
        {subtitle_text.text ? <p>{subtitle_text.text}</p> : null}
        {/*
        <FullWidthImageHeaderInnerLink>
          <a href="#content">{cta_text.text}</a>
        </FullWidthImageHeaderInnerLink>
        */}
      </FullWidthImageHeaderInner>
    </FullWidthImageHeaderContainer>
  );
};

export default HeaderInsights;
